import vueFilter from "@/common/filter/vuefilter.js";
import DatePicker from './plugin';

export default {
    data() {
        return {
        }
    },
    created() {
    },
    methods: {
        // 选择时间插件
        // startTime:时间限制的开始时间
        // endTime:时间限制的结束时间
        // selectedTime:已经选择的时间
        // target:事件广播，将选择的时间值传递出去
        // formatDate: 日期列表格式化，（显示 年-月-日，或者 年-月-日 时:分:秒） 默认 yyyy/MM/dd 年-月-日 时:分:秒
        openSelectTime(startTime, endTime, selectedTime, target, formatDate = 'yyyy-MM-dd hh:mm', dateType) {
            // this.setRouteListeners('closeVuxDatetime'); //安卓返回键
            let _this = this;

            startTime = startTime.replace(/T/g, " ");
            endTime = endTime.replace(/T/g, " ");
            selectedTime = selectedTime.replace(/T/g, " ");
            // 茶歇时间区间在会场时间区间内
            const tenantMaps = ['ipsen', 'roche', 'crossevent'];
            const targetMaps = ['tear-start', 'tear-end', 'tear2-start', 'tear2-end'];
            if(tenantMaps.includes(this.tenant_code) && targetMaps.includes(target)) {
                startTime = this.addParams.dtStart;
                endTime = this.addParams.dtEnd;
            }

            let valueTime = selectedTime ? vueFilter.formatDate(selectedTime, formatDate) : this.getCurrentDate();
            startTime = vueFilter.formatDate(startTime, formatDate);
            endTime = vueFilter.formatDate(endTime, formatDate);

            // 如果结束时间为当天0点，则增加23:59:59
            if(new Date(endTime).getHours() == 0 && new Date(endTime).getMinutes() == 0) {
                let timeContainer = +new Date(endTime) + 86399000;
                endTime = new Date(timeContainer);
            }

            DatePicker.show({
                currentDate: valueTime ? new Date(valueTime) : new Date(),
                minDate: startTime ? new Date(startTime) : new Date(),
                maxDate: endTime ? new Date(endTime) : new Date(),
                dateType: dateType || "datetime",
                async onConfirm(val) {
                    let temp = vueFilter.formatDate(val, "yyyy/MM/dd hh:mm");
                    // ipsen 茶歇时间区间在会场时间区间内
                    if(tenantMaps.includes(_this.tenant_code) && targetMaps.includes(target)) {
                        if(temp < _this.addParams.dtStart || temp > _this.addParams.dtEnd) {
                            _this.$toast('茶歇时间不能超出会场时间，请重新选择！')
                            return false;
                        }
                    }
                    // 通过 bus事件 将选择的时间 传出去
                    _this.$root.$eventHub.$emit(target, temp);
                    _this.setSelectTime && _this.setSelectTime(target, temp, formatDate)
                },
                onHide: function() {
                    // this.removeRouteListeners('closeVuxDatetime'); //安卓返回键
                }.bind(this),
            });
        },
        // 格式化 当前日期
        getCurrentDate() {
            let newDate = new Date(),
              y = newDate.getFullYear(),
              M = newDate.getMonth() + 1,
              d = newDate.getDate();
            return y + "-" + M + "-" + d;
        },
    }
}
