'use strict'
import Vue from "vue";
import DatePicker from './DatePicker.vue'

const DatePickerHandle = Vue.extend(DatePicker);
let instance;

export default {
    show({ currentDate, minDate, maxDate, dateType, onConfirm, title }) {
        instance = new DatePickerHandle({
            el: document.createElement('div'),
            data: {
                title: title,
            }
        })
        instance.dateType = dateType;
        instance.currentDate = currentDate;
        instance.minDate = minDate;
        instance.maxDate = maxDate;
        instance.show = true;

        instance.$on("confirm", function(res) {
            onConfirm(res);
        });

        document.body.appendChild(instance.$el)
        // Vue.nextTick(() => {

        // });
    },
    close() {
        instance.$modal.hide("simulator");
        instance.visible = false;
    }
}