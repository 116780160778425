<template>
    <van-popup v-model="show" position="bottom">
        <van-datetime-picker
                v-model="currentDate"
                :type="dateType"
                title=""
                :min-date="minDate"
                :max-date="maxDate"
                @confirm="confirm"
                @cancel="cancel"
        />
    </van-popup>
</template>

<script>
    import datePicker from "./DatePicker";
    import {Popup, DatetimePicker} from 'vant'
    export default {
        extends: datePicker,
        components: {
            [Popup.name]: Popup,
            [DatetimePicker.name]: DatetimePicker,
        },
        props: {
            show: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                dateType: "date",
                minDate: new Date(2020, 0, 1),
                maxDate: new Date(2025, 10, 1),
                currentDate: new Date()
            };
        },
        created() {},
        methods: {
            confirm(value) {
                this.$emit("confirm", value);
                this.show = false;
            },
            cancel() {
                this.show = false;
            }
        }
    };
</script>

<style scoped lang="scss"></style>
